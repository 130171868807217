<fieldset [id]="identifier" govukFieldset>
  <legend [size]="legendSize" govukLegend>
    <ng-container *ngIf="legend; else customLegend">{{ legend }}</ng-container>
    <ng-template #customLegend>
      <ng-content select="[govukLegend]" />
    </ng-template>
  </legend>
  <span *ngIf="hint" govukFieldsetHint>{{ hint }}</span>
  <govuk-error-message
    *ngIf="shouldDisplayErrors"
    [errors]="control.errors"
    [identifier]="identifier"
  />
  <div [class.govuk-checkboxes--small]="size === 'small'" class="govuk-checkboxes">
    <ng-container *ngFor="let option of options">
      <ng-container [ngTemplateOutlet]="option.optionTemplate" />
      <ng-container [ngTemplateOutlet]="option.conditionalTemplate" />
    </ng-container>
  </div>
</fieldset>
